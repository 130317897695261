exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-bundesarbeitsgericht-arbeitsgericht-entscheidet-kein-arbeitsverhaltnis-zwischen-klagerin-und-fluggesellschaft-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/bundesarbeitsgericht/arbeitsgericht-entscheidet-kein-arbeitsverhältnis-zwischen-klagerin-und-fluggesellschaft/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-bundesarbeitsgericht-arbeitsgericht-entscheidet-kein-arbeitsverhaltnis-zwischen-klagerin-und-fluggesellschaft-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-bundesarbeitsgericht-betriebsrat-scheitert-vor-gericht-aufhebung-einer-personalen-massnahme-abgelehnt-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/bundesarbeitsgericht/betriebsrat-scheitert-vor-gericht-aufhebung-einer-personalen-massnahme-abgelehnt/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-bundesarbeitsgericht-betriebsrat-scheitert-vor-gericht-aufhebung-einer-personalen-massnahme-abgelehnt-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-bundesarbeitsgericht-bundesarbeitsgericht-entscheidet-kein-arbeitsverhaltnis-bei-unerlaubter-arbeitnehmeruberlassung-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/bundesarbeitsgericht/bundesarbeitsgericht-entscheidet-kein-arbeitsverhältnis-bei-unerlaubter-arbeitnehmerüberlassung/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-bundesarbeitsgericht-bundesarbeitsgericht-entscheidet-kein-arbeitsverhaltnis-bei-unerlaubter-arbeitnehmeruberlassung-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-bundesarbeitsgericht-gericht-entscheidet-zugunsten-eines-schulhausmeisters-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/bundesarbeitsgericht/gericht-entscheidet-zugunsten-eines-schulhausmeisters/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-bundesarbeitsgericht-gericht-entscheidet-zugunsten-eines-schulhausmeisters-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-einfach-erklart-grundgesetz-fur-die-bundesrepublik-deutschland-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/einfach-erklärt/grundgesetz-fur-die-bundesrepublik-deutschland/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-einfach-erklart-grundgesetz-fur-die-bundesrepublik-deutschland-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-garten-ist-die-dieffenbachia-pflanze-giftig-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/garten/ist-die-dieffenbachia-pflanze-giftig/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-garten-ist-die-dieffenbachia-pflanze-giftig-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-garten-schneeglockchen-anbauen-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/garten/schneeglöckchen-anbauen/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-garten-schneeglockchen-anbauen-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-haushalt-kohlenmonoxid-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/haushalt/kohlenmonoxid/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-haushalt-kohlenmonoxid-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-itil-einfuhrung-in-devops-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/itil/einführung-in-devops/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-itil-einfuhrung-in-devops-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-mongodb-die-ultimative-checklist-fur-mongodb-sicherheit-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/mongodb/die-ultimative-checklist-für-mongodb-sicherheit/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-mongodb-die-ultimative-checklist-fur-mongodb-sicherheit-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-mongodb-how-to-set-up-scram-authentication-for-mongodb-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/mongodb/how-to-set-up-scram-authentication-for-mongodb/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-mongodb-how-to-set-up-scram-authentication-for-mongodb-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-mongodb-so-installierst-du-mongodb-6-0-community-edition-ce-unter-windows-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/mongodb/so-installierst-du-mongodb-6.0-community-edition-ce-unter-windows/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-mongodb-so-installierst-du-mongodb-6-0-community-edition-ce-unter-windows-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-nachrichten-antwerpen-kampft-gegen-drogen-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/nachrichten/antwerpen-kämpft-gegen-drogen/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-nachrichten-antwerpen-kampft-gegen-drogen-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-nachrichten-kampf-gegen-desinformation-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/nachrichten/kampf-gegen-desinformation/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-nachrichten-kampf-gegen-desinformation-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-4-moglichkeiten-zum-speichern-und-wiederherstellen-von-tabs-in-microsoft-edge-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/4-möglichkeiten-zum-speichern-und-wiederherstellen-von-tabs-in-microsoft-edge/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-4-moglichkeiten-zum-speichern-und-wiederherstellen-von-tabs-in-microsoft-edge-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-edge-canary-funktioniert-nicht-fehlerbehebung-und-losung-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/edge-canary-funktioniert-nicht-fehlerbehebung-und-lösung/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-edge-canary-funktioniert-nicht-fehlerbehebung-und-losung-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-edge-favoritenmenu-das-alte-zuruckholen-und-das-neue-verbessern-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/edge-favoritenmenü-das-alte-zuruckholen-und-das-neue-verbessern/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-edge-favoritenmenu-das-alte-zuruckholen-und-das-neue-verbessern-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-einfach-fehler-in-software-vermeiden-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/einfach-fehler-in-software-vermeiden/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-einfach-fehler-in-software-vermeiden-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-graphql-fuer-einsteiger-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/graphql-fuer-einsteiger/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-graphql-fuer-einsteiger-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-problembehebung-bei-1080-p-video-wiedergabeproblemen-in-chromium-edge-und-anderen-browsern-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/problembehebung-bei-1080p-video-wiedergabeproblemen-in-chromium-edge-und-anderen-browsern/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-problembehebung-bei-1080-p-video-wiedergabeproblemen-in-chromium-edge-und-anderen-browsern-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-refactoring-fur-hochwertige-software-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/refactoring-für-hochwertige-software/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-refactoring-fur-hochwertige-software-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-session-cookies-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/session-cookies/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-session-cookies-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-ssl-secure-socket-layer-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/ssl-secure-socket-layer/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-ssl-secure-socket-layer-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-strategien-fur-effektive-datenintegration-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/strategien-für-effektive-datenintegration/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-strategien-fur-effektive-datenintegration-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-das-absturzen-von-microsoft-edge-nach-wenigen-sekunden-behebt-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/wie-man-das-abstürzen-von-microsoft-edge-nach-wenigen-sekunden-behebt/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-das-absturzen-von-microsoft-edge-nach-wenigen-sekunden-behebt-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-die-letzten-lokalen-commits-in-git-ruckgangig-macht-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/wie-man-die-letzten-lokalen-commits-in-git-rückgangig-macht/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-die-letzten-lokalen-commits-in-git-ruckgangig-macht-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-einen-git-zweig-lokal-und-remote-loscht-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/wie-man-einen-git-zweig-lokal-und-remote-löscht/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-einen-git-zweig-lokal-und-remote-loscht-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-einen-lokalen-git-branch-umbenennt-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/wie-man-einen-lokalen-git-branch-umbenennt/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-einen-lokalen-git-branch-umbenennt-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-existierende-nicht-gepushte-commit-nachrichten-modifiziert-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/wie-man-existierende-nicht-gepushte-commit-nachrichten-modifiziert/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-existierende-nicht-gepushte-commit-nachrichten-modifiziert-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-nie-gruppieren-in-der-taskleiste-in-windows-11-aktiviert-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/wie-man-nie-gruppieren-in-der-taskleiste-in-windows-11-aktiviert/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-wie-man-nie-gruppieren-in-der-taskleiste-in-windows-11-aktiviert-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-windows-power-states-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/tech/windows-power-states/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-tech-windows-power-states-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-themenkomplex-internationale-sicherheitspolitik-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/de/themenkomplex/internationale-sicherheitspolitik/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-de-themenkomplex-internationale-sicherheitspolitik-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-mongodb-how-to-install-mongodb-6-0-community-edition-ce-on-windows-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/mongodb/how-to-install-mongodb-6.0-community-edition-ce-on-windows/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-mongodb-how-to-install-mongodb-6-0-community-edition-ce-on-windows-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-mongodb-how-to-set-up-scram-authentication-for-mongodb-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/mongodb/how-to-set-up-scram-authentication-for-mongodb/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-mongodb-how-to-set-up-scram-authentication-for-mongodb-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-mongodb-the-ultimate-mongodb-security-checklist-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/mongodb/the-ultimate-mongodb-security-checklist/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-mongodb-the-ultimate-mongodb-security-checklist-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-news-antwerp-fights-against-drugs-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/news/antwerp-fights-against-drugs/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-news-antwerp-fights-against-drugs-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-news-london-police-officer-sentenced-to-life-imprisonment-for-sexual-assault-and-abuse-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/news/london-police-officer-sentenced-to-life-imprisonment-for-sexual-assault-and-abuse/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-news-london-police-officer-sentenced-to-life-imprisonment-for-sexual-assault-and-abuse-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-4-ways-to-save-and-restore-tabs-in-microsoft-edge-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/4-ways-to-save-and-restore-tabs-in-microsoft-edge/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-4-ways-to-save-and-restore-tabs-in-microsoft-edge-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-edge-canary-not-working-troubleshooting-and-solution-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/edge-canary-not-working-troubleshooting-and-solution/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-edge-canary-not-working-troubleshooting-and-solution-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-edge-favorites-menu-bringing-back-the-old-and-improving-the-new-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/edge-favorites-menu-bringing-back-the-old-and-improving-the-new/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-edge-favorites-menu-bringing-back-the-old-and-improving-the-new-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-delete-a-git-branch-locally-and-remotely-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/how-to-delete-a-git-branch-locally-and-remotely/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-delete-a-git-branch-locally-and-remotely-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-enable-never-combine-taskbar-buttons-in-windows-11-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/how-to-enable-never-combine-taskbar-buttons-in-windows-11/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-enable-never-combine-taskbar-buttons-in-windows-11-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-fix-microsoft-edge-crashing-after-a-few-seconds-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/how-to-fix-microsoft-edge-crashing-after-a-few-seconds/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-fix-microsoft-edge-crashing-after-a-few-seconds-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-modify-existing-unpushed-commit-messages-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/how-to-modify-existing-unpushed-commit-messages/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-modify-existing-unpushed-commit-messages-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-rename-a-local-git-branch-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/how-to-rename-a-local-git-branch/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-rename-a-local-git-branch-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-undo-the-most-recent-local-commits-in-git-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/how-to-undo-the-most-recent-local-commits-in-git/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-how-to-undo-the-most-recent-local-commits-in-git-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-optimizing-time-series-data-management-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/optimizing-time-series-data-management/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-optimizing-time-series-data-management-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-refactoring-maintaining-high-quality-software-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/refactoring-maintaining-high-quality-software/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-refactoring-maintaining-high-quality-software-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-simple-ways-engineers-can-use-to-avoid-bugs-in-software-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/simple-ways-engineers-can-use-to-avoid-bugs-in-software/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-simple-ways-engineers-can-use-to-avoid-bugs-in-software-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-troubleshooting-1080-p-video-playback-issues-on-chromium-edge-and-other-browsers-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=E:/gatsby-blog/blog/src/content/en/tech/troubleshooting-1080p-video-playback-issues-on-chromium-edge-and-other-browsers/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-e-gatsby-blog-blog-src-content-en-tech-troubleshooting-1080-p-video-playback-issues-on-chromium-edge-and-other-browsers-index-mdx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */)
}

